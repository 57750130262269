/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    em: "em",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    ul: "ul",
    li: "li",
    a: "a",
    br: "br",
    ol: "ol"
  }, _provideComponents(), props.components), {Specialization, Boon, BuildLink, Skill, Attribute, Trait, Warning, Divider, Grid, GridItem, Traits, Card, Item, Effect, Condition, Video} = _components;
  if (!Attribute) _missingMdxReference("Attribute", true);
  if (!Boon) _missingMdxReference("Boon", true);
  if (!BuildLink) _missingMdxReference("BuildLink", true);
  if (!Card) _missingMdxReference("Card", true);
  if (!Condition) _missingMdxReference("Condition", true);
  if (!Divider) _missingMdxReference("Divider", true);
  if (!Effect) _missingMdxReference("Effect", true);
  if (!Grid) _missingMdxReference("Grid", true);
  if (!GridItem) _missingMdxReference("GridItem", true);
  if (!Item) _missingMdxReference("Item", true);
  if (!Skill) _missingMdxReference("Skill", true);
  if (!Specialization) _missingMdxReference("Specialization", true);
  if (!Trait) _missingMdxReference("Trait", true);
  if (!Traits) _missingMdxReference("Traits", true);
  if (!Video) _missingMdxReference("Video", true);
  if (!Warning) _missingMdxReference("Warning", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The ", React.createElement(_components.strong, null, React.createElement(Specialization, {
    text: "Power Quickness Firebrand",
    name: "Firebrand"
  })), " (also ", React.createElement(_components.strong, null, React.createElement(_components.em, null, "Quickness Firebrand")), " or ", React.createElement(_components.strong, null, React.createElement(_components.em, null, "Quickbrand")), ") provides permanent ", React.createElement(Boon, {
    name: "Quickness"
  }), " to the party while dealing good DPS."), "\n", React.createElement(_components.p, null, "It plays very similar to ", React.createElement(BuildLink, {
    build: "Power Dragonhunter",
    specialization: "Dragonhunter"
  }), " and ", React.createElement(BuildLink, {
    build: "Power Willbender",
    specialization: "Willbender"
  }), " with the addition of ", React.createElement(Specialization, {
    name: "Firebrand"
  }), " mantras and tomes. Thanks to those, the build is able to adapt to various situations with ", React.createElement(Boon, {
    name: "Stability"
  }), ", ", React.createElement(Boon, {
    name: "Resistance"
  }), ", reflects and pulls."), "\n", React.createElement(_components.p, null, "Using ", React.createElement(Skill, {
    name: "Bane Signet",
    id: "9093"
  }), " to break defiance bars also increases your allies' ", React.createElement(Attribute, {
    name: "Power"
  }), " by 216 for 10 seconds thanks to ", React.createElement(Trait, {
    name: "Perfect Inscriptions",
    id: "579"
  }), "."), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "Its worth mentioning that ", React.createElement(Specialization, {
    text: "Power Quickness Firebrand",
    name: "Firebrand"
  }), " is exceedingly strong when bosses phase quickly. For various T4 fractals, long fights, or if you happen to be in a slower group (most PuG groups), you want to run ", React.createElement(BuildLink, {
    build: "Condi Firebrand",
    specialization: "Firebrand"
  }), " as it provides much higher sustained DPS. If you can't evaluate yourself if this build is a good pick for your party, you should not be playing it!")), "\n", React.createElement(Divider, {
    text: "Equipment"
  }), "\n", React.createElement(Divider, {
    text: "Build"
  }), "\n", React.createElement(Grid, null, React.createElement(GridItem, {
    sm: "7"
  }, React.createElement(Traits, {
    traits1: "Radiance",
    traits1Selected: "Right Hand Strength,Retribution,Perfect Inscriptions",
    traits2: "Zeal",
    traits2Selected: "Fiery Wrath,Zealous Blade,Symbolic Avenger",
    traits3: "Firebrand",
    traits3Selected: "Liberators Vow,Weighty Terms,Loremaster",
    traits1Id: "16",
    traits1SelectedIds: "566,565,579",
    traits2Id: "42",
    traits2SelectedIds: "634,653,2017",
    traits3Id: "62",
    traits3SelectedIds: "2101,2063,2159"
  }), React.createElement(Card, {
    title: "Defiance Bar Damage"
  }, React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th), React.createElement(_components.th))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "9093",
    id: "9093"
  })), React.createElement(_components.td, null, "300 damage")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "9226",
    id: "9226"
  }), " (after ", React.createElement(Skill, {
    id: "9147",
    id: "9147"
  }), ")"), React.createElement(_components.td, null, "150 damage")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Hammer of Wisdom",
    id: "9125"
  })), React.createElement(_components.td, null, "200 damage")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Chapter 3: Heated Rebuke",
    id: "42449"
  }), " (F1)"), React.createElement(_components.td, null, "150 damage")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Chains of light",
    id: "9099"
  })), React.createElement(_components.td, null, "250 damage")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Sanctuary",
    id: "9128"
  })), React.createElement(_components.td, null, "750 damage"))))), React.createElement(Card, {
    title: "Alternative Weapons"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Greatswords, Scepters, Swords with ", React.createElement(Item, {
    name: "Night",
    type: "Sigil",
    disableText: true,
    id: "36053"
  }), "/", React.createElement(Item, {
    name: "impact",
    type: "Sigil",
    disableText: true,
    id: "24868"
  }), " and ", React.createElement(Item, {
    name: "Serpent Slaying",
    type: "Sigil",
    disableText: true,
    id: "24658"
  }), "/", React.createElement(Item, {
    name: "Impact",
    type: "Sigil",
    disableText: true,
    id: "24868"
  })), "\n", React.createElement(_components.li, null, "Greatswords and foci with (see ", React.createElement(_components.a, {
    href: "/guides/cheat-sheet"
  }, "Cheat Sheet"), ")"), "\n", React.createElement(_components.li, null, "Maces for symbol precast"), "\n", React.createElement(_components.li, null, "Hammer and/or Staff for ", React.createElement(Boon, {
    name: "Might"
  }), " stacking"), "\n"))), React.createElement(GridItem, {
    sm: "5"
  }, React.createElement(Card, {
    title: "Situational Skills"
  }, React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th), React.createElement(_components.th))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "9246",
    size: "big",
    disableText: true,
    id: "9246"
  })), React.createElement(_components.td, null, "A 1,200 range teleport to an ally.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Hallowed Ground",
    size: "big",
    disableText: true,
    id: "9253"
  })), React.createElement(_components.td, null, "When ", React.createElement(Boon, {
    name: "Stability"
  }), " is needed.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "9153",
    size: "big",
    disableText: true,
    id: "9153"
  })), React.createElement(_components.td, null, "When ", React.createElement(Boon, {
    name: "Stability"
  }), " or a stunbreak is needed.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "advance",
    size: "big",
    disableText: true,
    id: "9084"
  })), React.createElement(_components.td, null, "When ", React.createElement(Boon, {
    name: "Aegis"
  }), " is needed.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "9125",
    size: "big",
    disableText: true,
    id: "9125"
  })), React.createElement(_components.td, null, "Deals an additional 200 defiance bar damage.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "9251",
    size: "big",
    disableText: true,
    id: "9251"
  })), React.createElement(_components.td, null, "A stationary reflect lasting for 10 seconds.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "43357",
    size: "big",
    disableText: true,
    id: "43357"
  })), React.createElement(_components.td, null, "When ", React.createElement(Boon, {
    name: "Stability"
  }), " or a stunbreak is needed.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "9247",
    size: "big",
    disableText: true,
    id: "9247"
  })), React.createElement(_components.td, null, "A 1200 range teleport to an enemy.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Purging flames",
    size: "big",
    disableText: true,
    id: "9187"
  })), React.createElement(_components.td, null, "Cleanses conditions.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Sanctuary",
    size: "big",
    disableText: true,
    id: "9128"
  })), React.createElement(_components.td, null, "Huge CC and projectile destruction.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Mantra of lore",
    size: "big",
    disableText: true,
    id: "45460"
  })), React.createElement(_components.td, null, "Cleanses multiple conditions thanks to charges.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "renewed focus",
    size: "big",
    disableText: true,
    id: "9154"
  })), React.createElement(_components.td, null, "Recharges all virtue skills, grants ", React.createElement(Effect, {
    name: "Invulnerability"
  }), " for 3 seconds."))))))), "\n", React.createElement(Divider, {
    text: "Details"
  }), "\n", React.createElement(_components.p, null, "To keep up ", React.createElement(Boon, {
    name: "Quickness"
  }), ":"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Feel My Wrath",
    id: "29965"
  }), " whenever ready"), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "mantraofpotence",
    id: "40915"
  }), " and ", React.createElement(Skill, {
    name: "mantraofsolace",
    id: "41714"
  }), " (keep in mind that ", React.createElement(Skill, {
    name: "mantraofsolace",
    id: "41714"
  }), " only shares ", React.createElement(Boon, {
    name: "Quickness"
  }), " once every 7 seconds thanks to ", React.createElement(Trait, {
    name: " liberators vow",
    id: "2101"
  }), ")"), "\n", React.createElement(_components.li, null, "Only use these skills close to your allies - try to \"puke\" on them with the mantras"), "\n", React.createElement(_components.li, null, "Keep in mind that equipping/stowing a tome grants you 3 seconds of ", React.createElement(Boon, {
    name: "Quickness"
  }), " every 8 seconds thanks to ", React.createElement(Trait, {
    name: "swift scholar",
    id: "2062"
  }), "!"), "\n"), "\n", React.createElement(_components.p, null, "The DPS rotation is the same as ", React.createElement(_components.a, {
    href: "/builds/guardian/power-dragonhunter"
  }, "Power Dragonhunter"), ":"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If the encounter allows you to, you can prestack the following symbols:", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Equip Mace and precast ", React.createElement(Skill, {
    name: "Symbol of Faith",
    id: "9111"
  })), "\n", React.createElement(_components.li, null, "Equip Scepter and precast ", React.createElement(Skill, {
    name: "Symbol of Punishment",
    id: "9090"
  })), "\n", React.createElement(_components.li, null, "And finally, equip sword and cast ", React.createElement(Skill, {
    name: "Symbol of Blades",
    id: "9097"
  })), "\n", React.createElement(_components.li, null, "This way, when the encounter starts, 3 symbols will hit the target and get up your stacks of ", React.createElement(Trait, {
    name: "symbolicavenger",
    id: "2017"
  })), "\n"), "\n"), "\n", React.createElement(_components.li, null, "On greatsword:", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Symbol of Resolution",
    id: "9146"
  }), ", you should cancel the aftercast with weapon stowing or simply moving"), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Whirling Wrath",
    id: "9081"
  }), " while standing in a symbol inside the target's hitbox (if done correctly it results in 14 hits)"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "On Sword:", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Ray of Judgment",
    id: "9112"
  }), ", try if possible to cancel the aftercast with weapon swap, stow, or ", React.createElement(Skill, {
    name: "Symbol of Blades",
    id: "9097"
  })), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Symbol of Blades",
    id: "9097"
  })), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Zealots Defense",
    id: "9107"
  })), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Shield of Wrath",
    id: "9082"
  }), " when you're not receiving hits - use it shortly before breaking defiance bars. Can also be used to help with stacking ", React.createElement(Boon, {
    name: "Might"
  })), "\n"), "\n"), "\n", React.createElement(_components.li, null, "Apart from that:", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Bane Signet",
    id: "9093"
  }), " to break defiance bars and provide the group with a temporary 216 ", React.createElement(Attribute, {
    name: "Power"
  }), " buff"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "9226",
    id: "9226"
  }), " (after ", React.createElement(Skill, {
    id: "9147",
    id: "9147"
  }), ") is nice CC skill as well"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Sword of Justice",
    id: "9168"
  }), " for additional DPS and vulnerability. Also provides 3 seconds of ", React.createElement(Condition, {
    name: "Crippled"
  })), "\n", React.createElement(_components.li, null, "Scepter plays the same as the sword, just without ", React.createElement(Skill, {
    name: "Zealots Defense",
    id: "9107"
  })), "\n"), "\n"), "\n"), "\n", React.createElement(_components.p, null, "Tomes are very useful when no other skills are ready. ", React.createElement(Skill, {
    name: "Tome of Justice",
    id: "44364"
  }), " also gets refreshed every time an enemy dies (works with anomalies at Skorvald/Artsariiv/Arkk, knights at MAMA, and hallucinations at Siax)."), "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Tome of Courage",
    id: "42259"
  }), " and ", React.createElement(Skill, {
    name: "Tome of Resolve",
    id: "41780"
  }), " are great for high incoming damage scenarios."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Tome of Justice",
    id: "44364"
  }), " (F1):", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "chapterscorchedaftermath",
    id: "40015"
  }), " is a ticking AoE, good for single target and great for AoE DPS, provides fire field so make sure to use it before the fight to stack some might"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "epilogue ashes of the just",
    id: "42898"
  }), " makes your surrounding allies inflict ", React.createElement(Condition, {
    name: "Burning"
  }), " - worth using as precast"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Chapter 3: Heated Rebuke",
    id: "42449"
  }), " is an AoE pull (150 defiance bar damage)"), "\n", React.createElement(_components.li, null, "Often these skills are used as an opener"), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Tome of Resolve",
    id: "41780"
  }), " (F2):", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Skill 2 is a party condition cleanse"), "\n", React.createElement(_components.li, null, "Skill 3 grants ", React.createElement(Boon, {
    name: "Vigor"
  }), ", ", React.createElement(Boon, {
    name: "Regeneration"
  }), " and ", React.createElement(Boon, {
    name: "Swiftness"
  })), "\n", React.createElement(_components.li, null, "Skill 4 is a good party heal"), "\n", React.createElement(_components.li, null, "Skill 5 increases healing on allies for 8s by 33% and converts up to 5 conditions to boons"), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Tome of Courage",
    id: "42259"
  }), " (F3):", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Skill 1 grants ", React.createElement(Boon, {
    name: "Stability"
  }), " and ", React.createElement(Boon, {
    name: "Swiftness"
  })), "\n", React.createElement(_components.li, null, "Skill 3 is a 5s reflect"), "\n", React.createElement(_components.li, null, "Skill 4 grants ", React.createElement(Boon, {
    name: "Resistance"
  }), " and breaks stun"), "\n", React.createElement(_components.li, null, "Skill 5 grants ", React.createElement(Boon, {
    name: "Aegis"
  }), ", ", React.createElement(Boon, {
    name: "Protection"
  }), ", ", React.createElement(Boon, {
    name: "Stability"
  }), " and 300 toughness for 5 seconds"), "\n"), "\n"), "\n"), "\n", React.createElement(Divider, {
    text: "Rotation / Skill Usage"
  }), "\n", React.createElement(Grid, null, React.createElement(GridItem, {
    xs: "12",
    sm: "6"
  }, React.createElement(Card, {
    title: "Information"
  }, React.createElement(_components.p, null, "Golem rotations out of the raid builds are generally suboptimal in fractals due to ", React.createElement(Effect, {
    name: "Exposed"
  }), " and phases being much shorter compared to raids. The raid rotations are optimized for sustained DPS while in fractals a player needs the ability to adapt a rotation to the amount of time a group needs to finish a phase.", React.createElement(_components.br), "\n", "For that reason, you can find a video with openers, that are efficient to use here.")), React.createElement(Card, {
    title: "Firebrand openers"
  }, React.createElement(Video, {
    caption: "by MagicBot [dT], edited by Vince [dT]",
    youtube: "pFUHvaqPOO0"
  }))), React.createElement(GridItem, {
    xs: "12",
    sm: "6"
  }, React.createElement(Card, {
    title: "Precasting"
  }, React.createElement(_components.p, null, "If you have a ", React.createElement(_components.em, null, "Mistlock Singularity"), " present you can use these skills for precasts:"), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Cast ", React.createElement(Skill, {
    name: "tome of justice",
    id: "44364"
  }), ": ", React.createElement(Skill, {
    name: "chapterscorchedaftermath",
    id: "40015"
  }), " and ", React.createElement(Skill, {
    name: "Epilogue: Ashes of the just",
    id: "42898"
  })), "\n", React.createElement(_components.li, null, "Blast the fire field by casting ", React.createElement(Skill, {
    name: "Holy Strike",
    id: "9140"
  }), " or ", React.createElement(Skill, {
    name: "mightyblow",
    id: "9194"
  })), "\n", React.createElement(_components.li, null, "Use 3 charges of ", React.createElement(Skill, {
    name: "mantraofpotence",
    id: "40915"
  }), " and share ", React.createElement(Skill, {
    name: "mantraofsolace",
    id: "41714"
  }), " (keep in mind that ", React.createElement(Skill, {
    name: "mantraofsolace",
    id: "41714"
  }), " only shares ", React.createElement(Boon, {
    name: "Quickness"
  }), " once every 7 seconds thanks to ", React.createElement(Trait, {
    name: " liberators vow",
    id: "2101"
  }), ")"), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Stand your ground",
    id: "9153"
  }), " or ", React.createElement(Skill, {
    name: "saveyourselves",
    id: "9085"
  })), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Feelmywrath",
    id: "29965"
  })), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "banesignet",
    id: "9093"
  })), "\n", React.createElement(_components.li, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity")), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Feelmywrath",
    id: "29965"
  })), "\n"))), React.createElement(GridItem, {
    xs: "12",
    sm: "6"
  }, React.createElement(Card, {
    title: "Golem Rotation"
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Scepter/Sword Rotation:")), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "banesignet",
    profession: "guardian",
    id: "9093"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Sword of Justice",
    profession: "guardian",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Symbol of Punishment",
    profession: "guardian",
    id: "9090"
  }), " (Scepter/Sword 2)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Sword of Justice",
    profession: "guardian",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Ray of Judgment",
    profession: "guardian",
    id: "9112"
  }), " (Focus 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Shield of Wrath",
    profession: "guardian",
    id: "9082"
  }), " (Focus 5)"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Weapon Swap")), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Sword of Justice",
    profession: "guardian",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Symbol of Resolution ",
    profession: "guardian",
    id: "9146"
  }), " (GS 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Whirling Wrath",
    profession: "guardian",
    id: "9081"
  }), " (GS 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Leap of Faith",
    profession: "guardian",
    id: "9080"
  }), " (GS 3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Binding Blade",
    profession: "guardian",
    id: "9147"
  }), " (GS 5)"), "\n", React.createElement(_components.li, null, "If the phase lasts longer than this, continue with the SC rotation."), "\n"))), React.createElement(GridItem, {
    xs: "12",
    sm: "6"
  }, React.createElement(Card, {
    title: "Golem Rotation"
  }, React.createElement(Video, {
    youtube: "G1Y1u4ZwJh8",
    caption: "MajesticNoodle [BATS]"
  })))), "\n", React.createElement(Divider, {
    text: "Underwater combat"
  }), "\n", React.createElement(_components.p, null, "It is very much recommended to play ", React.createElement(BuildLink, {
    build: "Condi Firebrand",
    specialization: "Firebrand"
  }), " since it provides much better DPS compared to the power variant. If you still want to play ", React.createElement(Specialization, {
    text: "Power Quickness Firebrand",
    name: "Firebrand"
  }), " for whatever reason follow these rough guidelines."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Open with ", React.createElement(Skill, {
    name: "refraction",
    id: "9209"
  }), " for ", React.createElement(Boon, {
    name: "resolution"
  })), "\n", React.createElement(_components.li, null, "Cast ", React.createElement(Skill, {
    name: "purify",
    id: "9207"
  }), " and swap to spear. Camp spear now."), "\n", React.createElement(_components.li, null, "Priority list:", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Zealots flurry",
    id: "9190"
  }), " (Spear 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Symbol of spears",
    id: "9192"
  }), " (Spear 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "brilliance",
    id: "9191"
  }), " (Spear 3)"), "\n"), "\n"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
